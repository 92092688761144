.container{
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;

  .logo{
    display: block;
    width: 100%;
    max-width: 200px;
    margin: 0 auto;
  }
}